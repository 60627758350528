import React, { useEffect, useState } from 'react'
import './gallary.css'
import axios from "axios"
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import GallaryPop from './gallaryPop';

function Gallary() {

    const [data,setData] = useState([]);
    const [showPop,setShowPop] = useState(false)
    const [imageUrl,setImageUrl] = useState('');

    const getImgData = async () => {
      const storeRef = collection(db, `images`)
      const dataRef = await getDocs(storeRef)
      const allData = dataRef.docs.map(data =>
        ({ ...data.data(), id: data.id }))
      setData(allData)
      console.log(data)
    }
    useEffect(() => {
      getImgData()
      console.log(data)
    }, [])

    const handleImage = (image) => {
      setImageUrl(image)
      setShowPop(true)
    }

  return (
    <div className='gallary-container'>
        {
          showPop ? 
          <GallaryPop imageUrl={imageUrl} setShowPop={setShowPop} /> : ""
        }
        {
          data.map((data,key)=>{
            return(
              <div className='gallary-images' onClick={()=>handleImage(data.imgUrl)} >
                <img src={`${data.imgUrl}`} />
              </div>
            )
          })
        }
    </div>
  )
}

export default Gallary