import React from 'react'
import './photoSection.css';
import { Link } from 'react-router-dom';

function PhotoSection() {
    return (
        <div className='teacher_photo_container' >
            <div className='teacher_photo_header' >
                <h2>Teachers</h2>
                <Link to="/gallary" className='teacher_link'>
                    View All
                </Link>
            </div>
            <div className='teachers_container' >
                <img src='../../../../image/group-photo.jpg' />
                <img src='../../../../image/group-1-photo.jpg' />
            </div>
        </div>
    )
}

export default PhotoSection