import React from 'react'
import './gallary.css';

function GallaryPop({imageUrl, setShowPop}) {
  return (
    <div className='gallary_pop_container' onClick={()=>setShowPop(false)}>
        <img src={imageUrl} />
    </div>  
  )
}

export default GallaryPop